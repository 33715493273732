<template>
  <div class="home">
    <div class="full-width">
      <vueper-slides autoplay :touchable="false">
        <vueper-slide v-for="(img, i) in slides" :key="i" :image="img" />
      </vueper-slides>
    </div>
    <br />
    <div class="section">
      <div>
        <span class="section_subtitle">our very convenient</span> <br />
        <div class="section_title">Booking Process</div>
      </div>
      <div class="booking_flex">
        <div class="booking_flex_item">
          <i class="bi bi-telephone-inbound-fill"></i>
          <span class="booking_flex_item_title"><span class="colored">1.</span> Call Us</span>
          <span>Contact our number to start the booking process</span>
        </div>
        <div class="booking_flex_item">
          <i class="bi bi-currency-rupee"></i>
          <span class="booking_flex_item_title"><span class="colored">2.</span> No Hidden Charge</span>
          <span>We will always offer you the best prices</span>
        </div>
        <div class="booking_flex_item">
          <i class="bi bi-car-front-fill"></i>
          <span class="booking_flex_item_title"><span class="colored">3.</span> Car at Doorstep</span>
          <span>Once the car is booked, we will be at your doorstep for your convenience!</span>
        </div>
      </div>

    </div>
    <div class="section">
      <div>
        <span class="section_subtitle">Booking Service</span> <br />
        <span class="section_title">Car Rentals</span>
      </div>
      <CarFleet />
    </div>
    <div class="section">
      <div>
        <span class="section_subtitle">Testimonials</span> <br />
        <span class="section_title">What our clients say</span>
      </div>
    </div>

    <div class="testimonial_container">
      <div class="testimonials" v-for="testimonial in testimonials" :key="testimonial.name">
        <span class="testimonials_name">{{ testimonial.name }}
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
          <i class="bi bi-star-fill"></i>
        </span>
        {{ testimonial.review }}
      </div>
    </div>

    <div class="gethelp full-width">
      <div class="gethelp_container">
        <span class="section_subtitle">Need Help?</span>
        <span class="section_title">Contact Us</span>
        <span style="font-size: 19px">Our agents will provide top quality assistance</span>
        <span style="font-size: 14px; font-weight:800">24/7 CUSTOMER SUPPORT</span>
        <a href="tel:+919971835816">+91 99718 35816</a>
        <a href="tel:+918130045817">+91 81300 45817</a>
      </div>
    </div>
  </div>
</template>

<script>
import CarFleet from '@/components/CarFleet.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'HomeView',
  data() {
    return {
      slides: [require('@/assets/slide2.png'), require('@/assets/slide1.png'), require('@/assets/slide3.png')],
      testimonials: {
        1: {
          name: 'Anil Saxena',
          review: 'I have been using their services for last more than 7 years now and primarily for families functions like parties & marriages, airport pick & drop as well for my daily usages, intra as well as intercity. Mr. Gurpratap and his fleet team members have always been professional and courteous in their approach. The rates have been very reasonable and the condition of the fleet of cars, suvs and vans have been excellent, performance wise as well as physical condition wise.'
        },
        2: {
          name: 'Piyush Uniyal',
          review: 'I have been using their services since last 12 years , highly trustable and safe for all specially ladies and family . They have good fleet of cars and SUVs. Mr Pratap the owner is a humble yet cheerful gentleman . Wishing them the best .'
        },
        3: {
          name: 'Satinder Thakral',
          review: 'My experiences have been outstanding since last many years. This is my most favourite company and I suggest all my friends including female ones to depend on them.'
        },
      }
    }
  },
  components: {
    CarFleet,
    VueperSlides,
    VueperSlide
  },
  beforeCreate: function () {
    document.body.className = '';
    document.body.className = 'HomeView';
  },
}
</script>