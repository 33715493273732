<template>
  <site-navbar />
  <div class="content">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
  <site-footer />
  <a class="whatsapp_contact" href="https://wa.me/919971835816?text=Hi, I need to book a car" target="_blank">
      <i class="bi bi-whatsapp"></i>
  </a>
</template>

<script>
import SiteNavbar from '@/components/SiteNavbar.vue'
import SiteFooter from '@/components/SiteFooter.vue'
export default {
  name: 'App',
  components: {
    SiteNavbar,
    SiteFooter
  },
  mounted() {
    let header = document.querySelector(".header")
    header.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })
  }
}
</script>

<style>
@import '@/style.css';
</style>
