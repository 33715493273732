<template>
    <div class="nav_container">
        <div class="top_info_bar">
            <span>
                Contact at <a href="tel:+919971835816">99718 35816</a> or <a href="tel:+918130045817">81300 45817</a> | 
                <a href="mailto:gurpartap080@gmail.com">gurpartap080@gmail.com</a>
            </span>
        </div>
        <div id="nav">
            <span class="header">Arsh Tours And Travels</span>
      <div class="topnav">
        <router-link to="/">Home</router-link>
        <router-link to="/about">About Us</router-link>
        <router-link to="/packages">Trip Packages</router-link>
        <router-link to="/fleet">Our Fleet</router-link>
        <router-link to="/contact">Contact Us</router-link>
      </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'SiteNavbar'
}
</script>