<template>
    <div class="car_rentals">
        <div v-for="car in cars" :value="car.class" :key="car.class" 
        class="car_tile" :class="car.class">
          <!-- <img class="car_img" src="@\assets\cars\ertiga.jpg"> -->
          <div class="car_tile_info">
            <span>{{ car.name }}</span>
            <router-link class="viewDetailrouter" :to="`/fleet/` + car.class">
              <button class="outline_btn" @click="viewDetails(car)">
                View Details
              </button>
            </router-link>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'CarFleet',
  data () {
    return {
      cars: [
        {
          class: 'tiago',
          name: 'Tata Tiago',
        },
        {
          class: 'tigor',
          name: 'Tata Tigor',
        },
        {
          class: 'dzire',
          name: 'Maruti Suzuki Swift Dzire',
        },
        {
          class: 'glanza',
          name: 'Toyota Glanza',
        },
        {
          class: 'ertiga',
          name: 'Maruti Ertiga',
        },
        {
          class: 'rumion',
          name: 'Toyota Rumion',
        },
        {
          class: 'innova',
          name: 'Toyota Innova',
        },
        {
          class: 'innova_crysta',
          name: 'Toyota Innova Crysta',
        },
        {
          class: 'fortuner',
          name: 'Toyota Fortuner',
        },
        {
          class: 'endeavour',
          name: 'Ford Endeavour',
        },
        {
          class: 'traveller16',
          name: 'Tempo Traveller (16 seater)',
        },
        {
          class: 'traveller20',
          name: 'Tempo Traveller (20 seater)',
        },
      ]
    }
  },
  methods: {
    viewDetails(car) {
      // alert('View Details ' + car.class)
      console.log(car)
    }
  }
}
</script>
