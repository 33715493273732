import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/fleet',
    name: 'fleet',
    component: () => import('../views/FleetView.vue')
  },
  {
    path: '/packages',
    name: 'packages',
    component: () => import('../views/PackagesView.vue')
  },
  {
    path: '/fleet/dzire',
    name: 'dzire',
    component: () => import('../views/cars/DzireView.vue')
  },
  
  {
    path: '/fleet/endeavour',
    name: 'endeavour',
    component: () => import('../views/cars/EndeavourView.vue')
  },
  {
    path: '/fleet/glanza',
    name: 'glanza',
    component: () => import('../views/cars/GlanzaView.vue')
  },
  {
    path: '/fleet/innova',
    name: 'innova',
    component: () => import('../views/cars/InnovaView.vue')
  },
  {
    path: '/fleet/innova_crysta',
    name: 'innova_crysta',
    component: () => import('../views/cars/Innova_CrystaView.vue')
  },
  {
    path: '/fleet/rumion',
    name: 'rumion',
    component: () => import('../views/cars/RumionView.vue')
  },
  {
    path: '/fleet/traveller16',
    name: 'traveller16',
    component: () => import('../views/cars/Traveller16View.vue')
  },
  {
    path: '/fleet/traveller20',
    name: 'traveller20',
    component: () => import('../views/cars/Traveller20View.vue')
  },
  {
    path: '/fleet/ertiga',
    name: 'ertiga',
    component: () => import('../views/cars/ErtigaView.vue')
  },
  {
    path: '/fleet/fortuner',
    name: 'fortuner',
    component: () => import('../views/cars/FortunerView.vue')
  },
  {
    path: '/fleet/tiago',
    name: 'tiago',
    component: () => import('../views/cars/TiagoView.vue')
  },
  {
    path: '/fleet/tigor',
    name: 'tigor',
    component: () => import('../views/cars/TigorView.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/'}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
