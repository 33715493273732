<template>
    <div class="footer">
        <div class="footer_left">
            <span class="footer_main">© 2021 ARSH TOUR & TRAVELS</span>
            <span>Sector-1/69, G.F., Vaishali, Ghaziabad, UP</span>
        </div>
        <div class="footer_right">
            <a href="https://www.facebook.com/profile.php?id=100067172715036&mibextid=ZbWKwL" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/arsh_tour_and_travels" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="https://jsdl.in/DT-23HAZ1ZB" target="_blank">Jd</a>
            <a href="https://wa.me/919971835816?text=Hi, I need to book a car" target="_blank"><i class="bi bi-whatsapp"></i></a>
        </div>
    </div>
</template>